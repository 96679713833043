exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fullscreen-js": () => import("./../../../src/pages/fullscreen.js" /* webpackChunkName: "component---src-pages-fullscreen-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-it-js": () => import("./../../../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-dashboard-js": () => import("./../../../src/templates/Dashboard.js" /* webpackChunkName: "component---src-templates-dashboard-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/Industry.js" /* webpackChunkName: "component---src-templates-industry-js" */)
}

